<template>
  <section :id="sectionId">
    <h2 class="title">Оставьте свои данные для связи</h2>
    <form @submit.prevent="submit" class="contacts-form__form">
      <DefaultInput
        v-model="formData.phone"
        placeholder="+7 (000) 000-00-00"
        description="Номер телефона"
      />
      <DefaultInput
        v-model="formData.email"
        placeholder="email@company.ru"
        description="Адрес электронной почты"
      />
      <DefaultTextarea
        v-model="formData.comment"
        tag="textarea"
        description="Комментарий"
      />
      <button class="contacts-form__button" :disabled="isDisabledButton">
        Отправить заявку
      </button>
    </form>
  </section>
</template>
<script>
import DefaultInput from "@/components/base/DefaultInput";
import DefaultTextarea from "@/components/base/DefaultTextarea";
import { reactive, computed } from "vue";
import { useNotification } from "@kyvg/vue3-notification";
import { isValidEmail, isValidPhone } from "@/static/regexes";

export default {
  name: "ContactsForm",
  components: {
    DefaultInput,
    DefaultTextarea,
  },
  props: {
    sectionId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { notify } = useNotification();

    const isDisabledButton = computed(() => !formData.phone && !formData.email);

    const formData = reactive({
      phone: "",
      email: "",
      comment: "",
    });

    const checkContacts = () => {
      if (formData.phone) {
        const isValidPhoneContact = isValidPhone(formData.phone);
        if (!isValidPhoneContact) {
          notify({
            type: "warn",
            text: "Заполните корректно номер телефона",
          });
          return false;
        }
      }
      if (formData.email) {
        const isValidEmailContact = isValidEmail(formData.email);
        if (!isValidEmailContact) {
          notify({
            type: "warn",
            text: "Заполните корректно адрес электронной почты",
          });
          return false;
        }
      }
      return true;
    };

    const sendMessageToTelegram = async () => {
      let params = {
        chat_id: `${process.env.VUE_APP_TELEGRAM_CHAT_ID}`,
        text: `
          Новая заявка:

          Контактный номер: ${formData.phone}
          Адресс электронной почты: ${formData.email}
          Комментарий: ${formData.comment}
        `,
      };
      params = new URLSearchParams(params).toString();

      const url = `https://api.telegram.org/bot${process.env.VUE_APP_TELEGRAM_BOT_TOKEN}/sendMessage?${params}`;
      const response = await fetch(url);
      return response;
    };

    const submit = async () => {
      const isValidContacts = checkContacts();
      if (!isValidContacts) {
        return;
      }
      const response = await sendMessageToTelegram();
      if (response.ok) {
        notify({
          type: "success",
          title: "Успешно",
          text: "Спасибо за заявку. Мы свяжемся с Вами в ближайшее время",
        });
        formData.phone = "";
        formData.email = "";
        formData.comment = "";
      } else {
        notify({
          type: "warn",
          text: "Что-то пошло не так. Попробуйте позже",
        });
      }
    };

    return {
      formData,
      isDisabledButton,
      submit,
    };
  },
};
</script>
<style lang="scss">
.contacts-form {
  &__form {
    @include flex-column;
    gap: 16px;
  }

  &__button {
    height: 48px;
    padding: 12px 24px;
    border-radius: 8px;
    color: $cl-white;
    background: $cl-blue;
    transition: 0.5s;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
</style>
