<template>
  <section :id="sectionId">
    <h2 class="title">Основные направления работы</h2>
    <div class="main-directions__content">
      <div
        v-for="(group, i) in mainDirectionsGroups"
        :key="i"
        :class="[
          'main-directions__content-group',
          { 'cursor-pointer': group.items },
        ]"
        @click="group.items ? showGroupItems(group) : null"
      >
        <img
          class="main-directions__content-group-image"
          :src="require(`@/assets/main/${group.image}`)"
        />
        <div class="flex flex-gap-16 justify-content-space-between">
          <p class="subtitle color_white">
            {{ group.name }}
          </p>
          <i v-if="group.items" class="bx bx-info-circle color_white icon_32" />
        </div>
      </div>
    </div>
    <SidebarMainDirections
      v-if="sidebar.show"
      :show="sidebar.show"
      :group="sidebar.group"
      @close="sidebar.show = false"
    />
  </section>
</template>
<script>
import SidebarMainDirections from "@/components/SidebarMainDirections";
import { mainDirectionsGroups } from "@/static/static";
import { reactive } from "vue";
export default {
  name: "MainDirections",
  components: {
    SidebarMainDirections,
  },
  props: {
    sectionId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const sidebar = reactive({
      show: false,
      items: [],
    });

    const showGroupItems = (group) => {
      sidebar.group = group;
      sidebar.show = true;
    };

    return {
      showGroupItems,
      sidebar,
      mainDirectionsGroups,
    };
  },
};
</script>
<style lang="scss">
.main-directions {
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 40px;

    &-group {
      overflow: hidden;
      position: relative;
      height: 240px;
      padding: 24px;
      border-radius: 16px;

      &-image {
        @include parent-size;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        display: block;
        object-fit: cover;
        filter: brightness(70%);
      }
    }
  }
}

@media (max-width: 800px) {
  .main-directions {
    &__content {
      gap: 16px;
    }
  }
}

@media (max-width: 600px) {
  .main-directions {
    &__content {
      grid-template-columns: 1fr;
    }
  }
}
</style>
