<template>
  <section :id="sectionId" class="company-contacts">
    <div class="flex-column flex-gap-16">
      <h2 class="title">Контакты</h2>
      <p class="subtitle color_secondary">
        Общество с ограниченной ответственностью «Северное сияние»
      </p>
    </div>
    <div class="flex-column flex-gap-16">
      <div class="company-contacts__footer-info-contacts">
        <h3 class="caption">+8 (936) 310-87-00</h3>
        <h3 class="caption">sevmetpro@mail.ru</h3>
      </div>
      <p class="company-contacts__footer-info-text">
        г. Москва, ул. Земляной Вал, д. 9
      </p>
    </div>
  </section>
</template>
<script>
export default {
  name: "CompanyContacts",
  props: {
    sectionId: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.company-contacts {
  @include flex-column;
  justify-content: space-between;
  padding: 32px 36px;

  &__footer-info {
    &-contacts {
      display: flex;
      justify-content: space-between;
      gap: 16px;
    }

    &-text {
      font-size: 20px;
      color: $cl-secondary;
    }
  }
}

@media (max-width: 800px) {
  .company-contacts {
    &__footer-info {
      &-contacts {
        flex-direction: column;
      }
    }
  }
}
</style>
