const emailRegex = new RegExp(
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))/gi
);
const phoneSymbols = new RegExp(/^[+ \-()\d]*$/i);
const phoneRegex = new RegExp(/^[0-9]{5,13}$/i);

const isValidEmail = (value) => emailRegex.test(value);

const isValidPhone = (value) => {
  const isValidSymbols = phoneSymbols.test(value);
  if (!isValidSymbols) {
    return false;
  }
  const newPhone = (value && value.match(/[0-9]+/g)?.join("")) || "";
  return phoneRegex.test(newPhone);
};

export { isValidEmail, isValidPhone };
