<template>
  <header class="default-header">
    <div class="default-header__logo">
      <p class="text_weight_head text_xl text_uppercase">Северное</p>
      <p class="text_weight_head text_xl text_uppercase">сияние</p>
    </div>
    <nav class="default-header__navigation">
      <a
        v-for="link in navigationData"
        :key="link.value"
        :href="'#' + link.value"
        class="default-header__navigation-link"
      >
        {{ link.name }}
      </a>
    </nav>
    <div></div>
  </header>
</template>
<script>
export default {
  name: "DefaultHeader",
  props: {
    navigationData: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.default-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 16px;
  background: $cl-gray;

  &__logo {
    margin-right: 16px;
  }

  &__navigation {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    &-link {
      @include text_m;
      font-weight: $t-weight-accent;
      color: $cl-black;
      transition: 0.5s;

      &:hover {
        font-weight: $t-weight-head;
      }
    }
  }
}
</style>
