<template>
  <section :id="sectionId">
    <h2 class="title">Допонительные направления</h2>
    <div class="supplementary-directions__content">
      <div
        v-for="(item, i) in items"
        :key="i"
        class="supplementary-directions__content-item"
      >
        <img
          class="supplementary-directions__content-item-image"
          :src="require(`@/assets/supplementary/${item.image}`)"
        />
        <p class="subtitle color_white">
          {{ item.name }}
        </p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "SupplementaryDirections",
  props: {
    sectionId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const items = [
      {
        name: "Продажа строительной техники",
        image: "supplementary-sale.png",
      },
      {
        name: "Аренда строительной техники",
        image: "supplementary-rent.png",
      },
      {
        name: "Продажа строительного оборудования",
        image: "supplementary-equipment.png",
      },
    ];
    return {
      items,
    };
  },
};
</script>
<style lang="scss">
.supplementary-directions {
  &__content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 40px;

    &-item {
      overflow: hidden;
      position: relative;
      height: 480px;
      padding: 24px;
      border-radius: 16px;

      &-image {
        @include parent-size;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        display: block;
        object-fit: cover;
        filter: brightness(70%);
      }
    }
  }
}

@media (max-width: 800px) {
  .supplementary-directions {
    &__content {
      gap: 16px;

      &-item {
        height: 240px;
      }
    }
  }
}
</style>
