const sectionsOfWebsite = [
  {
    name: "Основные направления",
    value: "mainDirections",
  },
  {
    name: "Дополнительные направления",
    value: "supplementaryDirections",
  },
  {
    name: "Оставить заявку",
    value: "request",
  },
  {
    name: "Контакты",
    value: "contacts",
  },
];

const mainDirectionsGroups = [
  {
    value: "metal",
    name: "Металлопрокат",
    image: "main-metal.png",
    items: [
      {
        name: "Арматура рифленная А3 (А500С)",
        image: "metal-1.png",
      },
      {
        name: "Арматура гладкая А1 (А500С)",
        image: "metal-2.png",
      },
      {
        name: "Арматура А240",
        image: "metal-3.png",
      },
      {
        name: "Труба электросварная",
        image: "metal-4.png",
      },
      {
        name: "Оцинкованные трубы",
        image: "metal-5.png",
      },
      {
        name: "Труба квадратная, профильная",
        image: "metal-6.png",
      },
      {
        name: "Труба квадратная, профильная",
        image: "metal-6.png",
      },
      {
        name: "Швеллер гнутый",
        image: "metal-7.png",
      },
      {
        name: "Швеллер низколегированный",
        image: "metal-8.png",
      },
      {
        name: "Балка двутавровая",
        image: "metal-9.png",
      },
      {
        name: "Уголок равнополочный",
        image: "metal-10.png",
      },
      {
        name: "Уголок неравнополочный",
        image: "metal-11.png",
      },
      {
        name: "Уголок равнополочный низколегированный",
        image: "metal-12.png",
      },
      {
        name: "Квадрат стальной г/к",
        image: "metal-13.png",
      },
      {
        name: "Лист холоднокатаный",
        image: "metal-14.png",
      },
      {
        name: "Лист рифленный",
        image: "metal-15.png",
      },
      {
        name: "Лист горячекатаный",
        image: "metal-16.png",
      },
      {
        name: "Лист оцинкованный",
        image: "metal-17.png",
      },
      {
        name: "Полоса стальная г/к",
        image: "metal-18.png",
      },
    ],
  },
  {
    value: "tree",
    name: "Поставка пиломатериалов",
    image: "main-tree.png",
    items: [
      {
        name: "Доска обрезная",
        image: "tree-1.png",
      },
      {
        name: "Брус  обрезной ",
        image: "tree-2.png",
      },
      {
        name: "Оцилиндрованный бруc",
        image: "tree-3.png",
      },
      {
        name: "Строганная доска",
        image: "tree-4.png",
      },
      {
        name: "Половая доска",
        image: "tree-5.png",
      },
    ],
  },
  {
    value: "mixtures",
    name: "Сухие смеси",
    image: "main-mixtures.png",
  },
  {
    value: "blocks",
    name: "Газоблоки",
    image: "main-blocks.png",
    items: [
      {
        name: "Стеновые газоблоки",
        image: "blocks-1.png",
      },
      {
        name: "Перегородочные газоблоки",
        image: "blocks-2.png",
      },
    ],
  },
];

export { sectionsOfWebsite, mainDirectionsGroups };
