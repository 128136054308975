<template>
  <section class="start-screen">
    <img
      class="start-screen__image"
      :src="require('@/assets/start-image.jpg')"
    />
    <h1 class="title color_white">ООО «Северное сияние»</h1>
    <p class="color_white subtitle">
      Являемся поставщиками строительных материалов
    </p>
  </section>
</template>
<script>
export default {
  name: "StartScreen",
};
</script>
<style lang="scss">
.start-screen {
  @include flex-column;
  gap: 16px;
  overflow: hidden;
  position: relative;
  border-radius: 16px;

  &__image {
    @include parent-size;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    object-fit: cover;
    filter: brightness(70%);
  }
}
</style>
