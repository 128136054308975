<template>
  <div class="default-input">
    <p v-if="description" class="default-input__description">
      {{ description }}
    </p>
    <textarea
      v-model="query"
      class="default-input__inner"
      :placeholder="placeholder"
    />
  </div>
</template>
<script>
import { ref, watch } from "vue";
export default {
  name: "DefaultTextarea",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const query = ref("");

    watch(query, (value) => {
      emit("update:modelValue", value);
    });
    watch(
      () => props.modelValue,
      (value) => {
        if (value !== query.value) {
          query.value = value;
        }
      }
    );
    return {
      query,
    };
  },
};
</script>
<style lang="scss">
.default-input {
  &__inner {
    resize: none;
  }

  textarea {
    min-height: 96px;
  }
}
</style>
