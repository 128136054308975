<template>
  <aside class="aside">
    <div class="aside__overlay" @click="$emit('close')" />
    <div class="aside__content">
      <div class="flex justify-content-space-between align-items-center">
        <p class="subtitle">{{ group.name }}</p>
        <i
          class="bx bx-x icon_40 color_secondary cursor-pointer"
          @click="$emit('close')"
        />
      </div>
      <ul class="flex-column flex-gap-16 overflow-y-scroll">
        <li
          v-for="item in group.items"
          :key="item.name"
          class="flex-column flex-gap-16"
        >
          <p class="text_xl">{{ item.name }}</p>
          <img
            :src="require(`@/assets/products/${group.value}/${item.image}`)"
            class="aside__image"
          />
        </li>
      </ul>
    </div>
  </aside>
</template>
<script>
export default {
  name: "SidebarMainDirections",
  emits: ["close"],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    group: {
      type: Object,
      requied: true,
    },
  },
};
</script>
<style lang="scss">
.aside {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  &__content {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 200;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 40px;
    width: 320px;
    height: 100%;
    padding: 16px;
    background: $cl-white;
  }

  &__image {
    object-fit: cover;
    width: 200px;
    height: 160px;
    border-radius: 16px;
    border: 1px solid $cl-gray;
  }
}
</style>
