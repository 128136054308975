<template>
  <div class="default-input">
    <p v-if="description" class="default-input__description">
      {{ description }}
    </p>
    <input
      v-model="query"
      class="default-input__inner"
      :placeholder="placeholder"
    />
  </div>
</template>
<script>
import { ref, watch } from "vue";
export default {
  name: "DefaultInput",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const query = ref("");

    watch(query, (value) => {
      emit("update:modelValue", value);
    });
    watch(
      () => props.modelValue,
      (value) => {
        if (value !== query.value) {
          query.value = value;
        }
      }
    );
    return {
      query,
    };
  },
};
</script>
<style lang="scss">
.default-input {
  width: 100%;

  &__description {
    font-size: 14px;
    font-weight: $t-weight-accent;
    margin-bottom: 4px;
  }

  &__inner {
    width: 100%;
    height: 48px;
    padding: 12px 16px;
    font-size: 16px;
    color: $cl-secondary;
    border-radius: 8px;
    outline: 0;
    border: 1px solid $cl-gray;

    &:focus {
      border: 1px solid $cl-blue;
    }

    &::-moz-placeholder {
      color: $cl-secondary;
    }

    &::-webkit-input-placeholder {
      color: $cl-secondary;
    }
  }
}
</style>
