<template>
  <div class="default-layout">
    <div class="default-layout__inner">
      <DefaultHeader :navigation-data="sectionsOfWebsite" />
      <main class="default-layout__main">
        <StartScreen class="default-layout__start-screen" />
        <MainDirections
          :section-id="sectionsOfWebsite[0].value"
          class="default-layout__section"
        />
        <SupplementaryDirections
          :section-id="sectionsOfWebsite[1].value"
          class="default-layout__section"
        />
        <ContactsForm
          :section-id="sectionsOfWebsite[2].value"
          class="default-layout__section"
        />
        <CompanyContacts
          :section-id="sectionsOfWebsite[3].value"
          class="default-layout__section default-layout__company-contacts"
        />
      </main>
      <notifications />
    </div>
  </div>
</template>
<script>
import DefaultHeader from "@/components/DefaultHeader";
import StartScreen from "@/components/StartScreen";
import MainDirections from "@/components/MainDirections";
import SupplementaryDirections from "@/components/SupplementaryDirections";
import ContactsForm from "@/components/ContactsForm";
import CompanyContacts from "@/components/CompanyContacts";
import { sectionsOfWebsite } from "@/static/static";

export default {
  name: "App",
  components: {
    DefaultHeader,
    StartScreen,
    MainDirections,
    SupplementaryDirections,
    ContactsForm,
    CompanyContacts,
  },
  setup() {
    return {
      sectionsOfWebsite,
    };
  },
};
</script>
<style lang="scss">
.default-layout {
  @include parent-size;
  overflow-y: scroll;
  padding: 32px 80px 80px;

  &__inner {
    @include flex-column;
    gap: 80px;
    max-width: 1440px;
    height: 100%;
    margin: 0 auto;
  }

  &__main {
    @include flex-column;
    gap: 80px;
  }

  &__section {
    @include flex-column;
    gap: 48px;
  }

  &__start-screen {
    width: calc(100% + 160px);
    height: 570px;
    margin: 0 -80px;
    padding: 80px;
    border-radius: 16px;
  }

  &__company-contacts {
    width: 100%;
    height: 500px;
    border-radius: 16px;
    background: $cl-gray;
  }
}

@media (max-width: 800px) {
  .default-layout {
    padding: 16px;

    &__inner {
      gap: 32px;
    }

    &__main {
      gap: 32px;
    }

    &__section {
      gap: 16px;
    }

    &__start-screen {
      width: 100%;
      height: 285px;
      margin: 0;
      padding: 24px;
      border-radius: 16px;
    }

    &__company-contacts {
      height: auto;
    }
  }
}
</style>
